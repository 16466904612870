<template>
  <div id="app">
    <div class="row align-items-center bt-0 pb-0">
      <div class="col-md-12 mx-auto">
        <!-- progress -->
        <div class="progress mt-4">
          <div
            class="progress-bar"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="`width: ${progress}%;`"
          ></div>
        </div>
        <!-- end progress -->
        <div :class="(!show) ? 'fade' : ''">
          <div class="row" v-if="step == 1">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 title_form_section mt-3 mb-3">
                  Etes-vous propriétaire d'un logement ?
                </div>
                <div class="col-md-12">
                  <div
                    :class="[(data.keys.isOwner === 'true') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="isOwnertrue"
                      v-model="data.keys.isOwner"
                      @click="next()"
                      name="isOwner"
                      type="radio"
                      value="true"
                    />
                    <label for="isOwnertrue">Oui</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class="[(data.keys.isOwner === 'false') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="isOwnerfalse"
                      v-model="data.keys.isOwner"
                      @click="next()"
                      name="isOwner"
                      type="radio"
                      value="false"
                    />
                    <label for="isOwnerfalse">Non</label>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div class="row" v-if="step == 2">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 title_form_section mt-3 mb-3">
                  Combien reste-t-il à rembourser sur vos crédits immobiliers ?
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.realEstateCapital == '40000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="realEstateCapital40000"
                      v-model="data.keys.realEstateCapital"
                      @click="next()"
                      name="realEstateCapital"
                      type="radio"
                      value="40000"
                    />
                    <label for="realEstateCapital40000">Moins de 50 000€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.realEstateCapital == '60000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="realEstateCapital60000"
                      v-model="data.keys.realEstateCapital"
                      @click="next()"
                      name="realEstateCapital"
                      type="radio"
                      value="60000"
                    />
                    <label for="realEstateCapital60000"
                      >De 50 000 à 100 000€</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.realEstateCapital == '110000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="realEstateCapital110000"
                      v-model="data.keys.realEstateCapital"
                      @click="next()"
                      name="realEstateCapital"
                      type="radio"
                      value="110000"
                    />
                    <label for="realEstateCapital110000"
                      >De 100 000€ à 150 000€
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '160000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="realEstateCapital160000"
                      v-model="data.keys.realEstateCapital"
                      @click="next()"
                      name="realEstateCapital"
                      type="radio"
                      value="160000"
                    />
                    <label for="realEstateCapital160000"
                      >De 150 000€ à 200 000€
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.realEstateCapital == '210000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="realEstateCapital210000"
                      v-model="data.keys.realEstateCapital"
                      @click="next()"
                      name="realEstateCapital"
                      type="radio"
                      value="210000"
                    />
                    <label for="realEstateCapital210000"
                      >De 200 000 à 250 000€
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '260000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="realEstateCapital260000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="realEstateCapital"
                      type="radio"
                      value="260000"
                    />
                    <label for="realEstateCapital260000"
                      >Plus de 250 000€</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 3">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 title_form_section mt-3 mb-3">
                  Mensualités cumulées de vos différents crédits immobiliers
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '100') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate100"
                      v-model="data.keys.monthlyRealEstate"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="100"
                    />
                    <label for="monthlyRealEstate100"
                      >Moins de 200€ / Mois
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '300') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate300"
                      v-model="data.keys.monthlyRealEstate"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="300"
                    />
                    <label for="monthlyRealEstate300"
                      >De 200€ à 500€ / Mois</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '600') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate600"
                      v-model="data.keys.monthlyRealEstate"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="600"
                    />
                    <label for="monthlyRealEstate600"
                      >De 500€ à 700€ / Mois
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '800') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate800"
                      v-model="data.keys.monthlyRealEstate"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="800"
                    />
                    <label for="monthlyRealEstate800"
                      >De 700€ à 900€ / Mois
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '1000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate1000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="1000"
                    />
                    <label for="monthlyRealEstate1000"
                      >De 900€ à 1200€ / Mois
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '1300') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate1300"
                      v-model="data.keys.monthlyRealEstate"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="1300"
                    />
                    <label for="monthlyRealEstate1300"
                      >De 1200€ à 1500€ / Mois</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyRealEstate == '2000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyRealEstate2000"
                      v-model="data.keys.monthlyRealEstate"
                      @click="next()"
                      name="monthlyRealEstate"
                      type="radio"
                      value="2000"
                    />
                    <label for="monthlyRealEstate2000">Plus de 1500€ / Mois</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 4">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-center mt-3 mb-3">
                  <div class="title_form_section mt-3 mb-3">
                    Combien avez-vous de crédits à la consommation ?
                  </div>
                  <h6>
                    Crédit auto, crédit renouvelable, crédit travaux , paiements
                    en plusieurs fois etc…
                  </h6>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCreditNumber == '0') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCreditNumber0"
                      v-model="data.keys.consumptionCreditNumber"
                      @click="next()"
                      name="consumptionCreditNumber"
                      type="radio"
                      value="0"
                    />
                    <label for="consumptionCreditNumber0">0</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCreditNumber == '1') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCreditNumber1"
                      v-model="data.keys.consumptionCreditNumber"
                      @click="next()"
                      name="consumptionCreditNumber"
                      type="radio"
                      value="1"
                    />
                    <label for="consumptionCreditNumber1">1</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCreditNumber == '2') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCreditNumber2"
                      v-model="data.keys.consumptionCreditNumber"
                      @click="next()"
                      name="consumptionCreditNumber"
                      type="radio"
                      value="2"
                    />
                    <label for="consumptionCreditNumber2">2</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCreditNumber == '3') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCreditNumber3"
                      v-model="data.keys.consumptionCreditNumber"
                      @click="next()"
                      name="consumptionCreditNumber"
                      type="radio"
                      value="3"
                    />
                    <label for="consumptionCreditNumber3">3</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == '4') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCreditNumber4"
                      v-model="data.keys.consumptionCreditNumber"
                      @click="next()"
                      name="consumptionCreditNumber"
                      type="radio"
                      value="4"
                    />
                    <label for="consumptionCreditNumber4">4</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCreditNumber == '5') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCreditNumber5"
                      v-model="data.keys.consumptionCreditNumber"
                      @click="next()"
                      name="consumptionCreditNumber"
                      type="radio"
                      value="5"
                    />
                    <label for="consumptionCreditNumber5">Plus de 5</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 4 && consumptionCreditNumbertoggle">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-center title_form_section  mt-3 mb-3">
                  Combien reste-t-il à rembourser sur l’ensemble de vos crédits
                  à la consommation ?
                </div>
                <div class="col-md-6 consumptionCapitalref">
                  <div
                    
                    :class="[(data.keys.consumptionCapital == '4000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapital4000"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      name="consumptionCapital"
                      type="radio"
                      value="4000"
                    />
                    <label for="consumptionCapital4000">Moins de 5000€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCapital == '6000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapital6000"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      name="consumptionCapital"
                      type="radio"
                      value="6000"
                    />
                    <label for="consumptionCapital6000"
                      >de 5000€ à 10 000€</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCapital == '11000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapital11000"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      name="consumptionCapital"
                      type="radio"
                      value="11000"
                    />
                    <label for="consumptionCapital11000"
                      >de 10 000€ à 15 000€</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCapital == '16000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapital16000"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      name="consumptionCapital"
                      type="radio"
                      value="16000"
                    />
                    <label for="consumptionCapital16000"
                      >de 15 000€ à 20 000€
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.consumptionCapital == '21000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapital21000"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      name="consumptionCapital"
                      type="radio"
                      value="21000"
                    />
                    <label for="consumptionCapital21000">Plus de 20 000€</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 4 && consumptionCreditNumbertoggle">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-center title_form_section  mt-3 mb-3">
                  Mensualités cumulées de vos différents crédits conso ?
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyConsumptionRepaymentAmount == '100') ? 'inputGroup radioSelected' : 'inputGroup']"
                     ref='monthlyConsumptionRepaymentAmountref'
                  >
                    <input
                      id="monthlyConsumptionRepaymentAmount100"
                      v-model="data.keys.monthlyConsumptionRepaymentAmount"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="100"
                    />
                    <label for="monthlyConsumptionRepaymentAmount100"
                      >Moins de 200€ / Mois
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyConsumptionRepaymentAmount == '300') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyConsumptionRepaymentAmount300"
                      v-model="data.keys.monthlyConsumptionRepaymentAmount"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="300"
                    />
                    <label for="monthlyConsumptionRepaymentAmount300"
                      >De 200€ à 500€ / Mois</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyConsumptionRepaymentAmount == '600') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyConsumptionRepaymentAmount600"
                      v-model="data.keys.monthlyConsumptionRepaymentAmount"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="600"
                    />
                    <label for="monthlyConsumptionRepaymentAmount600"
                      >De 500€ à 700€ / Mois</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyConsumptionRepaymentAmount == '800') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyConsumptionRepaymentAmount800"
                      v-model="data.keys.monthlyConsumptionRepaymentAmount"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="800"
                    />
                    <label for="monthlyConsumptionRepaymentAmount800"
                      >De 700€ à 900€ / Mois
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyConsumptionRepaymentAmount == '1000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyConsumptionRepaymentAmount1000"
                      v-model="data.keys.monthlyConsumptionRepaymentAmount"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="1000"
                    />
                    <label for="monthlyConsumptionRepaymentAmount1000"
                      >De 900€ à 1200€ / Mois</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.monthlyConsumptionRepaymentAmount == '13000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="monthlyConsumptionRepaymentAmount13000"
                      v-model="data.keys.monthlyConsumptionRepaymentAmount"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="13000"
                    />
                    <label for="monthlyConsumptionRepaymentAmount13000"
                      >De 1200€ à 1500€ / Mois</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row" v-if="step == 5">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    vous reste-t-il plus de 12 000€ à rembourser sur tous vos
                    crédits à la consommation ?
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class="[(data.keys.consumptionCapital == 'true') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapitaltrue"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      type="radio"
                      value="true"
                    />
                    <label for="consumptionCapitaltrue">Oui</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class="[(data.keys.consumptionCapital == 'false') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="consumptionCapitalfalse"
                      v-model="data.keys.consumptionCapital"
                      @click="next()"
                      type="radio"
                      value="false"
                    />
                    <label for="consumptionCapitalfalse">Non</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
          <div class="row" v-if="step == 5">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Quels sont les revenus de votre ménage ?
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '800') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary800"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="800"
                    />
                    <label for="salary800">Moins de 900€/Mois</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '1000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary1000"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="1000"
                    />
                    <label for="salary1000">De 900€ à 1300€/Mois</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '1400') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary1400"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="1400"
                    />
                    <label for="salary1400">De 1300 à 1700€/Mois </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '1800') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary1800"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="1800"
                    />
                    <label for="salary1800">De 1700 à 2100€/Moiss</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '2200') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary2200"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="2200"
                    />
                    <label for="salary2200">De 2100 à 2800€/Mois</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '2900') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary2900"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="2900"
                    />
                    <label for="salary2900">De 2800€ à 4000€/Mois </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.salary == '4000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="salary400"
                      v-model="data.keys.salary"
                      @click="next()"
                      name="salary"
                      type="radio"
                      value="4000"
                    />
                    <label for="salary400">Plus de 4000€/Mois</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row fade-out" v-if="step == 6">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Traitement de votre dossier en cours ...
                  </div>
                </div>
                <div
                  class="col-md-12 mt-4 mb-3 text-center"
                  v-show="!showbutton"
                >
                  <div class="spinner-border text-success" role="status"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row fade-out" v-if="step == 7">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    D'après votre profil, vous êtes éligible à un regroupement
                    très attractif, veuillez renseigner vos coordonnées pour
                    vous adresser les résultats
                  </div>
                </div>
                <div class="offset-md-3 col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="d-flex ml-4">
                          <label class="mr-4">Civilité :</label>
                          <div class="form-check mr-4">
                            <input
                              class="form-check-input"
                              type="radio"
                              v-model="data.keys.civility"
                              name="civility"
                              id="M"
                              value="M"
                            />
                            <label class="form-check-label" for="M"> M </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              v-model="data.keys.civility"
                              name="civility"
                              id="F"
                              value="F"
                            />
                            <label class="form-check-label" for="F"> F </label>
                          </div>
                        </div>
                        <div class="text-danger mt-0" v-if="errors.civility">
                          {{errors.civility}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.firstname == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-model="data.firstName"
                          id="firstname"
                          name="firstname"
                          placeholder="Votre prénom"
                          value=""
                          type="text"
                        />
                        <div class="text-danger mt-0" v-if="errors.firstname">
                          {{errors.firstname}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.lastname == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-model="data.lastName"
                          id="lastname"
                          name="lastname"
                          placeholder="Votre Nom"
                          value=""
                          type="text"
                        />
                        <div class="text-danger mt-0" v-if="errors.lastname">
                          {{errors.lastname}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.DOB == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-model="data.keys.DOB"
                          id="DOB"
                          name="DOB"
                          placeholder="Votre Année de naissance"
                          value=""
                          type="text"
                          @keypress="dob"
                        />
                        <div class="text-danger mt-0" v-if="errors.DOB">
                          {{errors.DOB}}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.address == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-model="data.keys.address"
                          id="address"
                          name="address"
                          placeholder="Votre Adresse"
                          value=""
                          type="text"
                        />
                        <div class="text-danger mt-0" v-if="errors.address">
                          {{errors.address}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.zip == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-on:keyup="fetchzipcode"
                          v-model="data.zip"
                          id="zip"
                          name="zip"
                          placeholder="Votre code postal"
                          value=""
                          type="text"
                        />
                        <div class="text-danger mt-0" v-if="errors.zip">
                          {{errors.zip}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="cities.length > 0">
                      <div class="form-group">
                        <select
                          class="custom-select custom-select-lg"
                          v-model="data.keys.city"
                        >
                          <option
                            selected="selected"
                            value=""
                            disabled="disabled"
                          >
                            Votre ville
                          </option>
                          <option
                            v-for="(city,index) in cities"
                            :key="index"
                            :value="city.name"
                          >
                            {{city.name}}
                          </option>
                        </select>

                        <div class="text-danger mt-0" v-if="errors.city">
                          {{errors.city}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.email == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-model="data.email"
                          id="email"
                          name="email"
                          placeholder="Votre Email"
                          value=""
                          type="text"
                        />
                        <div class="text-danger mt-0" v-if="errors.email">
                          {{errors.email}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          :class="[(errors.phone == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"
                          v-model="data.phone"
                          id="phone"
                          name="phone"
                          placeholder="Votre Téléphone"
                          value=""
                          type="text"
                        />
                        <div class="text-danger mt-0" v-if="errors.phone">
                          {{errors.phone}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group d-flex justify-content-center">
                        <button
                          class="btn btn-success text-white mt-2"
                          @click="save()"
                        >
                          Recevoir les résultats
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 8">
            <div class="col-md-12 title_form_section mt-3 mb-3">
              Encore quelques informations sont nécessaires pour finaliser
              l’analyse
            </div>
            <div class="col-md-12 title_form_section p-4">
              <button class="btn btn-success text-white mt-4" @click="next()">
                Je Continue
              </button>
              <iframe v-if="url != ''" :src="url" width="0" height="0"></iframe>
            </div>
          </div>
          <div class="row" v-if="step == 9">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Souhaitez-vous une trésorerie supplémentaire ?
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '0') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="treasure0"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="0"
                    />
                    <label for="treasure0">0€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '3000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="treasure3000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="3000"
                    />
                    <label for="treasure3000">3000€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '5000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="treasure5000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="5000"
                    />
                    <label for="treasure5000">5000€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '7000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="treasure7000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="7000"
                    />
                    <label for="treasure7000">7000€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '9000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="treasure9000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="9000"
                    />
                    <label for="treasure9000">9000€</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.treasure == '12000') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="treasure12000"
                      v-model="data.keys.treasure"
                      @click="next()"
                      name="monthlyConsumptionRepaymentAmount"
                      type="radio"
                      value="12000"
                    />
                    <label for="treasure12000">12 000€</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 10">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Quel est votre statut professionnel ?
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'CDI') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractCDI"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="CDI"
                    />
                    <label for="contractCDI">En CDI</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'CDD') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractCDD"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="CDD"
                    />
                    <label for="contractCDD">En CDD</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'unemployed') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractunemployed"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="unemployed"
                    />
                    <label for="contractunemployed">Sans emploi</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'retired') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractretired"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="retired"
                    />
                    <label for="contractretired">Retraité</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'seasonal') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractseasonal"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="seasonal"
                    />
                    <label for="contractseasonal">Saisonnier(e)</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'public_sector') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractpublic_sector"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="public_sector"
                    />
                    <label for="contractpublic_sector">Fonctionnaire</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div
                    :class="[(data.keys.contract == 'self_employed_worker') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="contractself_employed_worker"
                      v-model="data.keys.contract"
                      @click="next()"
                      name="contract"
                      type="radio"
                      value="self_employed_worker"
                    />
                    <label for="contractself_employed_worker"
                      >Travailleur indépendant / Chef d'entreprise</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 11 && data.keys.isOwner == 'true'">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Quel est la valeur de votre bien immobilier  ?
                  </div>
                </div>
                <div class="col-md-12 text-center">
                 <input  :class="[(errors.realEstateValue == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']" v-model="data.keys.realEstateValue"  @keypress="onlyNumber" placeholder="€" />
                 <p class="text-danger mt-1">{{errors.realEstateValue}}</p>
                  <button class="btn btn-success text-white mt-4" @click="next()">Valider</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 11 && data.keys.isOwner == 'false'">
              <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Quel est votre loyer mensuel ?
                  </div>
                </div>
                <div class="col-md-12 text-center">
                 <input  :class="[(errors.rent == '') ? 'form-control input-lg' : 'form-control input-lg is-invalid']"  v-model="data.keys.rent"  @keypress="onlyNumber" placeholder="€"/>
                  <p class="text-danger mt-1">{{errors.rent}}</p>
                  <button class="btn btn-success text-white mt-4" @click="next()">Valider</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 12">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Quelle est votre situation familiale ?
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.familySituation == 'single') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="familySituationsingle"
                      v-model="data.keys.familySituation"
                      @click="next()"
                      name="familySituation"
                      type="radio"
                      value="single"
                    />
                    <label for="familySituationsingle">Célibataire</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.familySituation == 'maried') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="familySituationmaried"
                      v-model="data.keys.familySituation"
                      @click="next()"
                      name="familySituation"
                      type="radio"
                      value="maried"
                    />
                    <label for="familySituationmaried">Marié</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.familySituation == 'pacse') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="familySituationpacse"
                      v-model="data.keys.familySituation"
                      @click="next()"
                      name="familySituation"
                      type="radio"
                      value="pacse"
                    />
                    <label for="familySituationpacse">Pacsé</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.familySituation == 'divorced') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="familySituationdivorced"
                      v-model="data.keys.familySituation"
                      @click="next()"
                      name="familySituation"
                      type="radio"
                      value="divorced"
                    />
                    <label for="familySituationdivorced">Divorcé</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.familySituation == 'widower') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="familySituationwidower"
                      v-model="data.keys.familySituation"
                      @click="next()"
                      name="familySituation"
                      type="radio"
                      value="widower"
                    />
                    <label for="familySituationwidower">Veuf</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 13">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Combien avez vous d'enfants à charge ?
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.childNumber == '0') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="childNumber0"
                      v-model="data.keys.childNumber"
                      @click="next()"
                      name="childNumber"
                      type="radio"
                      value="0"
                    />
                    <label for="childNumber0">0</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.childNumber == '1') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="childNumber1"
                      v-model="data.keys.childNumber"
                      @click="next()"
                      name="childNumber"
                      type="radio"
                      value="1"
                    />
                    <label for="childNumber1">1</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.childNumber == '2') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="childNumber2"
                      v-model="data.keys.childNumber"
                      @click="next()"
                      name="childNumber"
                      type="radio"
                      value="2"
                    />
                    <label for="childNumber2">2</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.childNumber == '3') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="childNumber3"
                      v-model="data.keys.childNumber"
                      @click="next()"
                      name="childNumber"
                      type="radio"
                      value="3"
                    />
                    <label for="childNumber3">3</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.childNumber == '4') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="childNumber4"
                      v-model="data.keys.childNumber"
                      @click="next()"
                      name="childNumber"
                      type="radio"
                      value="4"
                    />
                    <label for="childNumber4">4</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="[(data.keys.childNumber == '5') ? 'inputGroup radioSelected' : 'inputGroup']"
                  >
                    <input
                      id="childNumber5"
                      v-model="data.keys.childNumber"
                      @click="next()"
                      name="childNumber"
                      type="radio"
                      value="5"
                    />
                    <label for="childNumber5">5+</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 14">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">
                    Êtes-vous fiché(e) à la Banque de France ?
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class='[(data.keys.bankOfFrance == true) ? "inputGroup radioSelected" : "inputGroup"]'
                  >
                    <input
                      id="bankOfFrancetrue"
                      v-model="data.keys.bankOfFrance"
                      @click="next()"
                      type="radio"
                      :value="true"
                    />
                    <label for="bankOfFrancetrue">Oui</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class='[(data.keys.bankOfFrance == false) ? "inputGroup radioSelected" : "inputGroup"]'
                  >
                    <input
                      id="bankOfFrancefalse"
                      v-model="data.keys.bankOfFrance"
                      @click="next()"
                      type="radio"
                      :value="false"
                    />
                    <label for="bankOfFrancefalse">Non</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 15">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mt-3 mb-3 text-center">
                  <div class="title_form_section">Co emprunteur ?</div>
                </div>
                <div class="col-md-12">
                  <div
                    :class='[(data.keys.coBorrower == true) ? "inputGroup radioSelected" : "inputGroup"]'
                  >
                    <input
                      id="coBorrowertrue"
                      v-model="data.keys.coBorrower"
                      @click="next()"
                      type="radio"
                      :value="true"
                    />
                    <label for="coBorrowertrue">Oui</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class='[(data.keys.coBorrower == false) ? "inputGroup radioSelected" : "inputGroup"]'
                  >
                    <input
                      id="coBorrowerfalse"
                      v-model="data.keys.coBorrower"
                      @click="next()"
                      type="radio"
                      :value="false"
                    />
                    <label for="coBorrowerfalse">Non</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step == 16">
            <div class="col-md-12 title_form_section mt-3 mb-3">Merci</div>
            <div class="col-md-12 title_form_section p-4">
              <svg
                width="150"
                height="150"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 507.2 507.2"
              >
                <circle cx="253.6" cy="253.6" r="253.6" fill="#32ba7c" />
                <path
                  d="M188.8 368l130.4 130.4c108-28.8 188-127.2 188-244.8v-7.2L404.8 152l-216 216z"
                  fill="#0aa06e"
                />
                <g fill="#fff">
                  <path
                    d="M260 310.4c11.2 11.2 11.2 30.4 0 41.6l-23.2 23.2c-11.2 11.2-30.4 11.2-41.6 0L93.6 272.8c-11.2-11.2-11.2-30.4 0-41.6l23.2-23.2c11.2-11.2 30.4-11.2 41.6 0L260 310.4z"
                  />
                  <path
                    d="M348.8 133.6c11.2-11.2 30.4-11.2 41.6 0l23.2 23.2c11.2 11.2 11.2 30.4 0 41.6l-176 175.2c-11.2 11.2-30.4 11.2-41.6 0l-23.2-23.2c-11.2-11.2-11.2-30.4 0-41.6l176-175.2z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <!--
                        <button class="btn btn-info text-white btn-x mt-4" @click="prev()" v-if="step !==  1 && step !== 10 && step !==  9">Précédent</button>
                       
                       <button class="btn btn-info text-white btn-x mt-4" @click="next()"
                         v-if="step == 10">Je Continue</button> 
                    
                          <button class="btn btn-info text-white btn-x mt-4"  v-if="step ==  9" @click="save()">Envoyer</button>
                          -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  data(){
    return{
      consumptionCreditNumbertoggle:false,
      idp :"",
      url:"",
      dates:[],
      step     : 1,
      progress :11.11,
       show: true,
       showbutton:false,
      cities   :{},
      data     :{
        "firstName": "",
        "lastName" : "",
        "phone"    : "",
        "email"    : "",
        "affsub"   : {},
        "zip"      : "",
        "sitekey"  : "Localiweb_tab",
        "area"     : "RAC",
        "keys":    {

               "contractYear": '2018',
               "bankOfFrance": false,
               "charges"     : '',
               "consumptionCapital":"",
                "city"       : '',
                "civility"   : '',
                "contract"   : '',
                "consumptionCreditNumber": '',
                "monthlyConsumptionRepaymentAmount": '',
                "childNumber": '0',
                "profession": '',
                "salary": '',
                "familySituation": 'single',
                "address": '',
                "treasure": '',
                "realEstateValue": '',
                "isOwner":"",
                "rent"   :'',
                "mortgageLoanNumber": '',
                "realEstateCapital" :'',
                "monthlyRealEstate" :'',
                "coBorrower":  false,
                "coIncome": '',
                "coContract": '',
                "coProfession": '',
                "DOB": '',
                "seniority": '',
                "ip": ''

             }
      },
      errors:{
         'seniority': '',
          "firstname": "",
          "lastname" : "",
          "phone"    : "",
          "email"    : "",
          "zip"      : "",
          "DOB"      : "",
          "civility" : "",
          "address"  : "",
          "treasure" : "",
          "realEstateValue":"",
          "contractYear" :"",
          "city"         :"",
          "monthlyConsumptionRepaymentAmount":"",
          rent:""

      }
    }
  },
  methods:{
    scrollToTop() {
                window.scrollTo(0,0);
     },
    dealy(){
          var self = this;
          setTimeout(function(){
             self.progress += 11.11;
               self.step++;
          },2000);
    },
    next(){
        var self = this;

            this.show = !this.show;

          setTimeout(function(){

            if(self.step == 1 && self.data.keys.isOwner == 'false'){
                 self.progress += 33.33;
                   self.step = 4;
                   self.show = !this.show;
                 return;
            }

            if(self.step == 4 && self.data.keys.consumptionCreditNumber == '0'){
                self.progress += 33.33;
                self.step++;
                self.show = !this.show;
                 return;
            }

          if(self.step == 4 && self.data.keys.consumptionCreditNumber != '0'){
               self.progress += 11.11;
              self.consumptionCreditNumbertoggle = true;
              self.show = !this.show;
              if(self.data.keys.consumptionCapital != "" && self.data.keys.monthlyConsumptionRepaymentAmount != ""){
                      self.step++;
                      return;
              }
              return 0;
            }


            if (self.step > 8) {
               if(self.step == 11 && self.data.keys.isOwner == 'true' && self.data.keys.realEstateValue == ""){
                 self.errors.realEstateValue = "Ce champ est obligatoire.";
                 self.show = !this.show;
                  return;
               }

             if(self.step == 11 && self.data.keys.isOwner == 'false' && self.data.keys.rent == ""){
                 self.errors.rent = "Ce champ est obligatoire.";
                 self.show = !this.show;
                  return;
               }

               if(self.data.keys.familySituation == "single" && self.step == 12){
                   self.step++;
                   self.step++;
                   self.show = !this.show;
                   return;
                }

                   if(self.data.keys.contract !== '' && self.step == 10){

                        if(self.data.keys.contract == 'unemployed'){
                            self.data.keys.profession = 'unemployed';
                        }
                      if(self.data.keys.contract == 'CDI' || self.data.keys.contract == 'CDD' || self.data.keys.contract == 'public_sector'){
                            self.data.keys.profession = 'employee';
                        }
                        if(self.data.keys.contract == 'retired'){
                            self.data.keys.profession = 'retired';
                        }
                          if(self.data.keys.contract == 'self_employed_worker'){
                            self.data.keys.profession = 'entrepreneur';
                        }
                    }


              
               axios.post('https://ractwo.aidweb.ma/web.php',self.data)
                  .then(res=>{
                    console.log(res.data)
                    if(res.data.status == 200){
                          self.show = !this.show;
                         self.step++;
                     }

                  })
                  .catch(err=>console.log(err))
                

            }
            else{
              self.progress += 11.11;
              self.step++;
               self.show = !this.show;
               if(self.step == 6){
                 self.dealy();
               }
           }

          },500);


    },
    prev(){

       this.progress -= 11.11;
       this.step--;
    },
    rangdate(){
       var date = new Date();
         var year = date.getFullYear();
       for(var i = 1950;i < year+1 ;i++){
         this.dates.push(i);
       }
    },
     Random(min, max) {
      return Math.floor(min + Math.random()*(max + 1 - min))
    },
    onlyNumber($event) {
       let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
       if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {

          $event.preventDefault();
       }
    },
   isEmpty(champ) {
      return (champ == '') ? true : false;
    },
    EmailValidate(email){
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
               return re.test(email);
    },
    dob($event){
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
         const re = /^\d{4}$/;
            console.log(keyCode)
      if((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
     }
      if(re.test(this.data.keys.DOB)){
            $event.preventDefault();
      }
    },
   PhoneValidate(phone){
       const phoneExp = /^((\+)33|0|0033)[1-9](\d{2}){4}$/g;
        return phoneExp.test(phone);
    },
    save(){
        if(this.step == 7){
             if(this.isEmpty(this.data.keys.civility)){
              this.errors.civility = "Ce champ est obligatoire.";
              this.scrollToTop();
              return;
            }
              this.errors.civility = '';
             if(this.isEmpty(this.data.firstName)){
              this.errors.firstname = "Ce champ est obligatoire.";
               this.scrollToTop();
              return;
            }
              this.errors.firstname = '';

            if(this.isEmpty(this.data.lastName)){
              this.errors.lastname = "Ce champ est obligatoire.";
               this.scrollToTop();
              return;
            }
              this.errors.lastname = '';

            if(this.isEmpty(this.data.keys.DOB)){
              this.errors.DOB = "Ce champ est obligatoire.";
               this.scrollToTop();
              return;
            }
            /*
            if(!this.dob(this.data.keys.DOB)){
                this.errors.DOB = "veuillez saisir la date de naissance correcte";
                 this.scrollToTop();
                 return;
             }
             */
                 var date = new Date();
            var year = date.getFullYear();
             if((parseInt(this.data.keys.DOB) < 1900)){

                this.errors.DOB = "Vous devez saisir un nombre compris entre 1900 et "+year;
                 this.scrollToTop();
                 return;
             }


            if(parseInt(this.data.keys.DOB) > parseInt(year)){


                this.errors.DOB = "Vous devez saisir un nombre compris entre 1900 et "+year;
                 this.scrollToTop();
                 return;
             }
              this.errors.DOB = '';

            if(this.isEmpty(this.data.keys.address)){
              this.errors.address = "Ce champ est obligatoire.";
                 this.scrollToTop();
              return;

            }
             this.errors.address = '';
            if(this.isEmpty(this.data.zip)){
              this.errors.zip = "Ce champ est obligatoire.";
               this.scrollToTop();
              return;
            }

            if(this.cities === 0){
              this.errors.zip = "Code postal incorrect";
              return;
            }
              this.errors.zip = '';



            if(this.isEmpty(this.data.keys.city)){
              this.errors.city = "Ce champ est obligatoire.";
              return;
            }
             this.errors.city = '';



            /*
            if(this.isEmpty(this.data.keys.treasure)){
              this.errors.treasure = "Ce champ est obligatoire.";
              return;
            }
              this.errors.treasure = '';
             
             if(this.isEmpty(this.data.keys.realEstateValue) && this.data.keys.isOwner == "true"){
              this.errors.realEstateValue = "Ce champ est obligatoire.";
              return;
            }
              this.errors.realEstateValue = '';
              */

           if(this.isEmpty(this.data.keys.contractYear)){
              this.errors.contractYear = "Ce champ est obligatoire.";
              return;
            }
              this.errors.contractYear = '';
            /*
            if(this.isEmpty(this.data.keys.monthlyConsumptionRepaymentAmount)){
              this.errors.monthlyConsumptionRepaymentAmount = "Ce champ est obligatoire.";
              return;
            }
              this.errors.monthlyConsumptionRepaymentAmount = '';
              */

         }
          if(this.isEmpty(this.data.email)){
              this.errors.email = "Ce champ est obligatoire.";
               //this.scrollToTop();
              return;
            }
            if(!this.EmailValidate(this.data.email)){
                this.errors.email = "email non valide";
                // this.scrollToTop();
                 return;
             }
              this.errors.email = '';
            if(this.isEmpty(this.data.phone)){
              this.errors.phone = "Ce champ est obligatoire.";
               this.scrollToTop();
              return;
            }
            if(!this.PhoneValidate(this.data.phone)){
                 this.errors.phone = "teléphone non valide";
                   return;
             }
              this.errors.phone = '';
    
        /* start  realEstateCapital */
          if(this.data.keys.realEstateCapital == '40000'){
                var realEstateCapitalValue_40000 = this.Random(30,50);
                this.data.keys.realEstateCapital =   realEstateCapitalValue_40000 * 1000;
          } 

          if(this.data.keys.realEstateCapital == '60000'){
                var realEstateCapitalValue_60000 = this.Random(50,100);
                this.data.keys.realEstateCapital =   realEstateCapitalValue_60000 * 1000;
          } 
          if(this.data.keys.realEstateCapital == '110000'){
                var realEstateCapitalValue_110000 = this.Random(100,150);
                this.data.keys.realEstateCapital =   realEstateCapitalValue_110000 * 1000;
          } 
         if(this.data.keys.realEstateCapital == '160000'){
                var realEstateCapitalValue_160000 = this.Random(150,200);
                this.data.keys.realEstateCapital =   realEstateCapitalValue_160000 * 1000;
          } 
          if(this.data.keys.realEstateCapital == '210000'){
                var realEstateCapitalValue_210000 = this.Random(200,250);
                this.data.keys.realEstateCapital =   realEstateCapitalValue_210000 * 1000;
          } 
          if(this.data.keys.realEstateCapital == '250000'){
                var realEstateCapitalValue_250000 = this.Random(250,350);
                this.data.keys.realEstateCapital =   realEstateCapitalValue_250000 * 1000;
          } 
         /* end  realEstateCapital */
       
         /* monthlyRealEstate */

         if(this.data.keys.monthlyRealEstate == '100'){
                var monthlyRealEstate_100 = this.Random(10,20);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_100 * 10;
          } 
         
         if(this.data.keys.monthlyRealEstate == '300'){
                var monthlyRealEstate_300 = this.Random(20,50);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_300 * 10;
          }  
        
        if(this.data.keys.monthlyRealEstate == '600'){
                var monthlyRealEstate_600 = this.Random(50,70);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_600 * 10;
        }  
        
        if(this.data.keys.monthlyRealEstate == '800'){
                var monthlyRealEstate_800 = this.Random(70,90);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_800 * 10;
        }  
       if(this.data.keys.monthlyRealEstate == '1000'){
                var monthlyRealEstate_1000 = this.Random(90,120);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_1000 * 10;
        }  
       if(this.data.keys.monthlyRealEstate == '1300'){
                var monthlyRealEstate_1300 = this.Random(120,150);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_1300 * 10;
        }  
        if(this.data.keys.monthlyRealEstate == '2000'){
                var monthlyRealEstate_2000 = this.Random(150,300);
                this.data.keys.monthlyRealEstate =   monthlyRealEstate_2000 * 10;
        }  
         /* end monthlyRealEstate */
        /*consumptionCapital */
         if(this.data.keys.consumptionCapital == '4000'){
                var consumptionCapital_4000 = this.Random(200,400);
                this.data.keys.consumptionCapital =   consumptionCapital_4000 * 10;
        } 
        if(this.data.keys.consumptionCapital == '6000'){
                var consumptionCapital_6000 = this.Random(500,1000);
                this.data.keys.consumptionCapital =   consumptionCapital_6000 * 10;
        } 

       if(this.data.keys.consumptionCapital == '11000'){
                var consumptionCapital_11000 = this.Random(1000,1500);
                this.data.keys.consumptionCapital =   consumptionCapital_11000 * 10;
        } 
        if(this.data.keys.consumptionCapital == '16000'){
                var consumptionCapital_16000 = this.Random(1500,2000);
                this.data.keys.consumptionCapital =   consumptionCapital_16000 * 10;
        } 
       if(this.data.keys.consumptionCapital == '21000'){
                var consumptionCapital_21000 = this.Random(2000,4000);
                this.data.keys.consumptionCapital =   consumptionCapital_21000 * 10;
        } 
         /*end consumptionCapital */


         /* monthlyConsumptionRepaymentAmount */
        if(this.data.keys.monthlyConsumptionRepaymentAmount == '100'){
                var monthlyConsumptionRepaymentAmount_100 = this.Random(10,20);
                this.data.keys.monthlyConsumptionRepaymentAmount =   monthlyConsumptionRepaymentAmount_100 * 10;
        } 

        if(this.data.keys.monthlyConsumptionRepaymentAmount == '300'){
                var monthlyConsumptionRepaymentAmount_300 = this.Random(20,50);
                this.data.keys.monthlyConsumptionRepaymentAmount =   monthlyConsumptionRepaymentAmount_300 * 10;
        } 

         if(this.data.keys.monthlyConsumptionRepaymentAmount == '600'){
                var monthlyConsumptionRepaymentAmount_600 = this.Random(50,70);
                this.data.keys.monthlyConsumptionRepaymentAmount =   monthlyConsumptionRepaymentAmount_600 * 10;
        } 
      
        if(this.data.keys.monthlyConsumptionRepaymentAmount == '800'){
                var monthlyConsumptionRepaymentAmount_800 = this.Random(70,90);
                this.data.keys.monthlyConsumptionRepaymentAmount =   monthlyConsumptionRepaymentAmount_800 * 10;
        } 
       if(this.data.keys.monthlyConsumptionRepaymentAmount == '1000'){
                var monthlyConsumptionRepaymentAmount_1000 = this.Random(90,120);
                this.data.keys.monthlyConsumptionRepaymentAmount =   monthlyConsumptionRepaymentAmount_1000 * 10;
        } 
      
       if(this.data.keys.monthlyConsumptionRepaymentAmount == '1500'){
                var monthlyConsumptionRepaymentAmount_1500 = this.Random(120,150);
                this.data.keys.monthlyConsumptionRepaymentAmount =   monthlyConsumptionRepaymentAmount_1500 * 10;
        } 
          /*end monthlyConsumptionRepaymentAmount */

          /* salary */
            if(this.data.keys.salary == '800'){
                var salary_800 = this.Random(70,90);
                this.data.keys.salary =   salary_800 * 10;
            } 

            if(this.data.keys.salary == '1000'){
                var salary_1000 = this.Random(90,130);
                this.data.keys.salary =   salary_1000 * 10;
            } 

            if(this.data.keys.salary == '1400'){
                var salary_1400 = this.Random(130,170);
                this.data.keys.salary =   salary_1400 * 10;
            } 
           if(this.data.keys.salary == '1800'){
                var salary_1800 = this.Random(170,210);
                this.data.keys.salary =   salary_1800 * 10;
            }
           if(this.data.keys.salary == '2200'){
                var salary_2200 = this.Random(210,280);
                this.data.keys.salary =   salary_2200 * 10;
            } 
            if(this.data.keys.salary == '2900'){
                var salary_2900 = this.Random(280,400);
                this.data.keys.salary =   salary_2900 * 10;
            } 
            if(this.data.keys.salary == '4000'){
                var salary_4000 = this.Random(400,600);
                this.data.keys.salary =   salary_4000 * 10;
            } 
           /* end salary */

         if(this.data.keys.isOwner == 'true'){
              this.data.keys.mortgageLoanNumber = '1';
         }

         


      
         this.data.keys.DOB = this.data.keys.DOB+'-01-01';
      
         axios.post('https://ractwo.aidweb.ma/web.php',this.data)
              .then(res=>{
                console.log(res.data)
                  this.url = "https://rachat-credit-meilleures-conditions.fr/confirmation/";
                  this.step++;

              })
              .catch(err=>console.log(err))

           /*
          axios.post('http://localhost/zip/web.php',this.data)
              .then(res=>{
                console.log(res.data)
                if(res.data.status == 200){
                    this.step++;
                }

              })
              .catch(err=>console.log(err))
             */
             // this.step++;
    },
     getIp(){
      axios.get('https://jsonip.com',{ mode: 'cors' }).then(res=>{
           console.log(res.data.ip);
           this.data.keys.ip = res.data.ip;
      }).catch(err=>{
            console.log(err);
      });
    },
    fetchzipcode(){
      axios.get('https://rac.aidweb.ma/zipcode.php?code='+this.data.zip,{
            responseType: "json",
         })
           .then(res=>{
                 if(res.data == 0){
                   this.cities = res.data;
                 }else{
                    this.errors.zip = "";
                   this.cities = res.data;
                   //console.log(this.cities.length);
                   if(this.cities.length == 1){
                    this.data.keys.city = this.cities[0].name;
                   }
                 }


           })
           .catch(err=>console.log(err));
    }

  },
  created(){

    //this.rangdate();

    this.getIp();
  }

}
</script>

<style scoped>
.btn-x {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  font-size: 12px;
  color: #222 !important;
}

.fade {
  transition: all 0.3s ease;
  opacity: 0.2;
}
</style>
